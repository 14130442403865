const works = [
  {
    imgPath: "/design.webp",
    title: "UX and UI Design",
    description:
      "We design beautiful, functional and purposeful apps, webapps and websites. From Wireframes to MVP to Pixel-Perfect designs. Complex workflows simplified but also beauty of a brand brought out by the simplicity.",
  },
  {
    imgPath: "/dev.webp",
    title: "Development",
    description:
      "We engineer and build software, web and mobile applications, APIs and more. Our team is experienced with modern technologies and programming languages. Our processes are effective and lean. We utilize agile project management, continuous integration and delivery to build.",
  },
  {
    imgPath: "/cloud.webp",
    title: "Cloud, Devops, Hosting",
    description:
      "We run your application on the Cloud or in a European Data Center depending on your requirements. Scalable, high-performance and cost effective. We support fully automated deployments, local or test environments. Complex requirements such as Client Data.",
  },
  {
    imgPath: "/devs.webp",
    title: "Dedicated Developers",
    description:
      "We provide you talented and experienced developers for you to manage your project. Frontend developers, backend developers, full-stack developers, UX designers or devops.",
  },
];

export default works;
