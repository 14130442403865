import React from "react";
import JourneyCard from "../components/JourneyCard";
import journey from "../content/journey";
import Container from "../hoc/Container";

const Journey = () => {
  return (
    <>
      <Container>
        <div className="text-center space-y-3">
          <h2 className="text-primary text-4xl font-black">The Digital Journey</h2>
          <p className="text-[#282c47]">“Everything Starts as a Dream, but Requires Action”</p>
        </div>
        {journey.map(({ imgPath, title, description }, index) => (
          <JourneyCard
            key={index}
            imgPath={imgPath}
            title={title}
            description={description}
            index={index}
          />
        ))}
      </Container>
      {/* <img src="/services.png" className="w-full" alt="" /> */}
      <Container>
        <div className="text-xl md:text-2xl font-bold text-secondary text-center space-y-3">
          <p>
            Everva has a playbook to make{" "}
            <span className="text-primary font-black">your digital journey</span> a success.
          </p>
          <p className="text-primary font-black">Effective. Successful.</p>
          <p>We want to be a part of your success story.</p>
        </div>
      </Container>
    </>
  );
};

export default Journey;
