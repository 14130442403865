import React from "react";

const JourneyCard = ({ imgPath, title, description, index }) => {
  return (
    <div
      className={`md:flex items-center justify-between gap-x-16 ${
        index % 2 === 0 ? "flex-row" : "flex-row-reverse"
      }`}
    >
      <img src={imgPath} className="h-[300px] md:h-full object-cover w-full md:w-1/2" alt="" />
      <div className="text-center md:text-left mt-5 md:mt-0 space-y-3 md:space-y-0">
        <h3 className="text-primary text-xl md:text-2xl font-black">{title}</h3>
        <p className="text-secondary text-base md:text-lg">{description}</p>
      </div>
    </div>
  );
};

export default JourneyCard;
