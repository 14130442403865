const testimonials = [
  {
    imgPath: "/profile1.jpeg",
    name: "Olukunle Kayode",
    position: "CEO Neone Technology",
    review:
      "It was a comforting to have Everva help us make NEONE a reality.",
  }
  ,
  {
    imgPath: "/profile2.jpeg",
    name: "Bilal Ahmad",
    position: "Product Growth Specialist",
    review:
      "Everva was a fresh breath after years of frustration.",
  }
  ,
  {
    imgPath: "/profile3.jpeg",
    name: "Atakan Kantarcı ",
    position: "CEO Helteca",
    review:
      "Everva has been a reliable partner in designing and developing Helteca.",
  }
];

export default testimonials;
