import React from "react";
import Container from "../hoc/Container";
import WorkCard from "../components/WorkCard";
import works from "../content/works";

const OurWork = () => {
  return (
    <>
      <Container>
        <h2 className="text-primary text-3xl md:text-4xl font-black text-center">What we do</h2>
        <div className="grid md:grid-cols-2 gap-x-7 gap-y-8 md:gap-y-16">
          {works.map(({ imgPath, title, description }, index) => (
            <WorkCard key={index} imgPath={imgPath} title={title} description={description} />
          ))}
        </div>
      </Container>
      <hr />
    </>
  );
};

export default OurWork;
