import React from "react";
import TechCard from "../components/TechCard";
import techStack from "../content/techStack";
import Container from "../hoc/Container";

const TechStack = () => {
  return (
    <Container className="bg-[#f8f8f9]">
      <h2 className="text-primary text-3xl font-black text-center">
        Our solid and versatile technology stack
      </h2>
      <div className="flex flex-wrap items-center justify-center gap-x-5 gap-y-10">
        {techStack.map(({ imgPath, name, type }, index) => (
          <TechCard key={index} imgPath={imgPath} name={name} type={type} />
        ))}
      </div>
    </Container>
  );
};

export default TechStack;
