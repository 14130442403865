import Container from "../hoc/Container";
import React from "react";
import Carousel from "../components/Carousel";
import testimonials from "../content/testimonials";

const Testimonials = () => {
  return (
    <Container className="bg-primary overflow-x-hidden">
      <div className="text-center text-white space-y-4">
        <h2 className="text-3xl font-black">They Trust Us</h2>
        <p className=" text-lg opacity-70">And we trust them</p>
      </div>
      <Carousel items={testimonials} />
    </Container>
  );
};

export default Testimonials;
