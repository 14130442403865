import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import CarouselCard from "./CarouselCard";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Carousel = ({ items }) => {
  const { width } = useWindowDimensions();
  return (
    <ResponsiveCarousel
      showThumbs={false}
      showIndicators={false}
      showStatus={false}
      showArrows={false}
      autoPlay={true}
      infiniteLoop={true}
      centerMode={true}
      centerSlidePercentage={width <= 768 ? 100 : width <= 1024 ? 70 : 50}
      interval={4000}
      transitionTime={1000}
    >
      {items.map(({ imgPath, name, position, review }, index) => (
        <CarouselCard
          key={index}
          imgPath={imgPath}
          name={name}
          position={position}
          review={review}
          index={index}
        />
      ))}
    </ResponsiveCarousel>
  );
};

export default Carousel;
