import React from "react";
import Container from "../hoc/Container";

const ContactUs = () => {
  return (
    <Container className="bg-primary">
      <h2 className="text-white text-3xl font-black text-center">
        Contact Us at{" "}
        <a className="underline text-l" href="mailto:info@everva.co">
          info@everva.co
        </a>
      </h2>
    </Container>
  );
};

export default ContactUs;
