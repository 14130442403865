const journey = [
  {
    imgPath: "/ux.png",
    title: "Concept & UX",
    description:
      "You have an idea, perhaps a vision. We help you refine it, tune it, and we give you the first scan of your new baby: Functional wireframes that makes you want to see your baby grow.",
  },
  {
    imgPath: "/des_and_dev.png",
    title: "Design & Development",
    description:
      "Time to turn that concept into a product. An intensive and fruitful collaboration. Designers, Developers and stakeholders. Teamwork. Big spike in our caffeine intake. But it is worth it.",
  },
  {
    imgPath: "/launch.png",
    title: "Launch & Operate",
    description:
      "Last polishes. “One more thing” moments. Before taking the curtains off, there is still a lot to do. Infrastructure ready. Check. Systems secured. Check. Content ready and error free check. Check. Check. Check... We are ready to GO.",
  },
  {
    imgPath: "/evolve.png",
    title: "Evolve & Support",
    description:
      "The Go-Live is just a start. Knowledge transfer, support and adapt. We are behind the curtain. Watching your show. Watching you grow. We are here to help you evolve and help you scale.",
  },
];

export default journey;
