import React, { useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";

const Main = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsAnimationComplete(true);
    }, 6300);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={`z-10 h-24 transition-all duration-300 ${
          isFixed
            ? "fixed bg-white shadow-lg top-0 w-full py-4 px-5 xl:px-40 h-20 sm:h-24"
            : "absolute top-6 left-0 xl:left-40"
        }`}
      >
        <img
          src={`${isFixed ? "/ews-black.png" : "/ews-white.png"}`}
          className={` pl-10 transition-opacity duration-500 w-[12rem] mt-2 
           ${isAnimationComplete ? "opacity-100" : "opacity-85 "}`}
          alt="Everva"
        />
      </header>
      <div className="h-screen bg-gradient-to-br w-full from-[#139e7e] to-[#091361] py-10 px-40">
        <div className="text-center text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full space-y-5 max-w-[1230px]">
          <TypeAnimation
            wrapper="h1"
            sequence={[
              100,
              "Making startups easier",
              100,
              "Making startups ",
              100,
              "Making startups easier for you.",
            ]}
            className="text-4xl xs:text-[42px] md:text-7xl leading-tight font-black"
          />
          <TypeAnimation
            sequence={[4100, "We build MVPs within weeks"]}
            className="text-xl md:text-2xl"
            wrapper="p"
          />
        </div>
      </div>
    </>
  );
};

export default Main;
