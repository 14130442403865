import React from "react";

const TechCard = ({ imgPath, name, type }) => {
  return (
    <div className="text-center text-xs">
      <div className="bg-white shadow-md max-w-[200px] py-10 px-10 flex items-center justify-center mb-2 min-h-[120px] max-h-[120px] h-full">
        <img src={imgPath} alt={name} className="max-h-[44px] h-max w-max object-contain" />
      </div>
      <h4 className="text-secondary">{name}</h4>
      <p className="text-[#adaab7]">{type}</p>
    </div>
  );
};

export default TechCard;
