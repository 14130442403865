import React from "react";

const CarouselCard = ({ imgPath, name, position, review }) => {
  return (
    <div className="w-11/12 md:w-auto max-w-xl relative pt-6 px-2 2xs:px-8 h-max pb-32 xs:pb-0 xs:h-56 rounded-lg bg-white mx-5 mr-5">
      <div className="flex space-x-2 2xs:space-x-4">
        <img src="/quotes.png" className="w-4 h-4" alt="" />
        <p className="text-[#282c47] font-medium text-left">{review}</p>
      </div>
      <div className="flex absolute bottom-0 left-2 2xs:left-8 right-0 items-end gap-x-0 2xs:gap-x-4">
        <img src={imgPath} className="w-24 h-24 relative top-5 rounded-md" alt="" />
        <div className="px-6 py-4 rounded-md bg-[#f8f8f9] w-full text-left">
          <p className="text-[#02072f] font-medium">{name}</p>
          <p className="text-secondary text-sm">{position}</p>
        </div>
      </div>
    </div>
  );
};

export default CarouselCard;
