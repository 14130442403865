const techStack = [
  {
    imgPath: "/aws-logo.png",
    name: "AWS",
    type: "Cloud Service",
  },
  {
    imgPath: "/django-logo.png",
    name: "Django",
    type: "Python Web Framework",
  },
  {
    imgPath: "/laravel-logo.png",
    name: "Laravel",
    type: "PHP Web Framework",
  },
  {
    imgPath: "/node.webp",
    name: "Node JS",
    type: "JS Runtime",
  },
  {
    imgPath: "/react.webp",
    name: "React",
    type: "JS Framework",
  },
  {
    imgPath: "/vue-logo.png",
    name: "Vue JS",
    type: "JS Frontend Framework",
  },
  {
    imgPath: "/nextjs-logo.png",
    name: "Next JS",
    type: "JS Framework",
  },
  {
    imgPath: "/react-native.png",
    name: "React Native",
    type: "Mobile App Framework",
  },
  {
    imgPath: "/ethereum-logo.png",
    name: "Ethereum",
    type: "Blockchain",
  },
  {
    imgPath: "/solana.png",
    name: "Solana",
    type: "Blockchain",
  },
  {
    imgPath: "/unity-logo.png",
    name: "Unity 3D",
    type: "Game Development",
  },
];

export default techStack;
